<template lang="">
    <div class="main">
        <div class="lesson-frm">
            <div class="head">
              <h3 class="title">{{$t("lesson.edit")}}</h3>
            </div>
            <div class="body">
              <FormLesson/>
            </div>
        </div>
        <MetaTags 
            title="出品リスト｜ResMom相談online"
            description="リセマム相談オンラインの出品リストです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>
<script>
import FormLesson from "./components/form.vue"
export default {
    name: "EditLesson",
    components: {
      FormLesson
    }
}
</script>
<style lang="">
    
</style>